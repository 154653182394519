.search-bar {
    width: 100%;
    max-width: 600px;
    height: 40px;
    background-color: var(--color-background-secondary);
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding:0px 15px;
    box-sizing: border-box;
}

.search-bar:hover {
    background-color: var(--color-hover);
}

.search-icon-container {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-icon {
    width: 24px;
    height: 24px;
    padding-right: 8px;
    color: var(--color-icon);
    cursor: pointer;
}

.search-bar input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    font-size: 16px;
    outline: none;
    border: none;
    color: var(--color-icon);
}

@media screen and (max-width: 600px) {
    .search-bar {
        width: 90%;
        height: 32px;
        padding:0px 7px;
        margin-top: 4px;
    }

    .search-bar input {
        font-size: 14px;
    }

    .search-icon {
        padding-right: 4px;
    }

}