.floating-create-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-button);
    position: fixed;
    bottom: 5%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--color-primary-font);
    cursor: pointer;
    visibility:hidden;
}

@media screen and (max-width: 600px) {

    .floating-create-button {
        visibility:visible;
    }
    
}