.home {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-primary-font);
    padding-bottom: 20px;
}

.no-more-posts {
    width: 100%;
    padding: 16px 12px;
    font-weight: bold;
    color: var(--color-faint);
    box-sizing: border-box;
    background-color: var(--color-background-secondary);
}