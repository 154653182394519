.content-holder {
    width: 600px;
    min-height: calc(100vh - 60px);
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


@media (max-width: 600px) {

    .content-holder {
        width: 100%;
    }
  }