.drawer {
    width: 240px;
    height: calc(100vh - 60px);
    margin-top: 60px;
    background-color: var(--color-background-primary);
    position: fixed;
    left: 0;
    color: var(--color-primary-font);
    padding: 10px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 99;
    transition: left 0.3s ease-in-out, background-color 0.4s ease;
}

.drawer-title {
    text-align: left;
    width: 100%;
    padding: 4px 16px;
    box-sizing: border-box;
    color: var(--color-faint-light);
    font-weight: bold;
}

.drawer.closed {
    left: -240px;
}

.drawer-list-item {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 16px;
    box-sizing: border-box;
}

.drawer-list-item:hover {
    background-color: var(--color-hover);
}

.drawer-list-item img {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 15px;
}

.drawer-list-item svg {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 15px;
    color: var(--color-border);
}

@media screen and (max-width: 600px) {
    .drawer {
        margin-top: 40px;
        height: calc(100vh - 40px);
    }

    .drawer-title {
        padding-top:8px;
    }
}