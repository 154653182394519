.logo {
    font-size: 1.5rem;
    font-weight: bold;
    height: 40px;
    color: var(--color-primary-font);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-icon-container {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.menu-icon {
    width: 24px;
    height: 24px;
}

.menu-icon-container:hover {
    background-color: var(--color-hover);
}

@media screen and (max-width: 600px) {
    .logo {
        font-size: 1.2rem;
        height: 32px;
    }

    .menu-icon-container {
        width: 32px;
        height: 32px;
        margin-right: 4px;
    }
    
    .menu-icon {
        width: 20px;
        height: 20px;
    }
}