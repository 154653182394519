.notifications {
    width: 300px;
    height: 500px; /* Set a maximum height for the dropdown */
    overflow-y: auto;
    border-radius: 5px;
    position: absolute;
    top: 60px;
    right: 80px;
    color: var(--color-primary-font);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-background-primary);
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);
}

.notifications-list-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 16px;
    box-sizing: border-box;
}

.notifications-list-item:hover {
    background-color: var(--color-hover);
}


.notification-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.notification-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
    text-align: left;
}

.notification-text-container > * {
    line-height: 1.3;
    color: var(--color-faint);
}

.notification-username {
    font-weight: bold;
}

.notification-text-container span {
    color:var(--color-faint-light);
    font-weight: 100;
}

.notifications-target {
    width: 100px;
    height: 10px;
    font-size: 9px;
    color: transparent;
}