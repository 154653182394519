.backdrop {
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    position: fixed;
    z-index: 100;
    background-color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 600px;
    height: fit-content;
    padding:20px;
    border-radius: 20px;
    box-sizing: border-box;
    opacity: 1;
    background-color: var(--color-background-secondary);
    color: var(--color-primary-font);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.close-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 4px 8px;
}

.title {
    width: 100%;
    height: 100%;
    text-align: start;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
}