.create-post {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-primary-font);
}

.create-post-title {
    padding: 10px 0px;
}

.input-container {
    width: 100%;
    height: fit-content;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-category {
    width: 50%;
}

.input-title {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    background-color: transparent;
    color: var(--color-primary-font);
    font-size: 20px;
    font-weight: bold;
    margin: 5px 0px;
}

.input-description {
    width: 100%;
    height: 200px;
    padding: 12px 16px;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    background-color: transparent;
    color: var(--color-primary-font);
    font-size: 16px;
    margin: 5px 0px;
    resize: none;
}

.input-button-holder {
    width: 100%;
    text-align: right;
    margin: 5px 0px;
}

.input-button {
    height: 40px;
    border-radius: 50px;
    padding: 0px 16px;
    cursor: pointer;
    color: var(--color-primary-font);
    font-size: 0.875rem;
    border: none;
    font-weight: bold;
    background-color: var(--color-button);
}

@media screen and (max-width: 600px) {
    .create-post {
        width: 90%;
    }

    .input-container {
        margin-top: 10px;
        padding: 10px;
    }

    .select-category {
        width: 100%;
    }

    .create-post-title {
        padding: 5px 0px;
        font-size: 24px;
    }

    .input-button {
        height: 32px;
        padding: 0px 12px;
    }

    .input-button-holder {
        margin: 0px;
    }
}