.navbar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    position: fixed;
    box-sizing: border-box;
    gap: 10px;
    background-color: var(--color-background-primary);
    border-bottom: 1px solid var(--color-border);
    z-index: 100;
}

@media screen and (max-width: 600px) {
    .navbar {
        height: 45px;
        gap: 6px;
        /* justify-content: flex-start */
    }
}


