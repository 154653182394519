.login {
    width: 100%;
    height: fit-content;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding:10px 0px;
}

input {
    width: 90%;
    height: fit-content;
    padding: 10px 16px;
    border: 1px solid var(--color-border);
    border-radius: 10px;
    outline: none;
    box-sizing: border-box;
    background-color: transparent;
    color: var(--color-primary-font);
    font-size: 14px;
    margin: 10px 0px;
}

.login button {
    width: 90%;
    height: 40px;
    border-radius: 50px;
    margin: 10px 0px;
    font-size: 14px;
    outline: none;
    border: none;
    cursor:pointer;
    color: var( --color-primary-font);
    font-weight: bold;
    background-color: var(--color-button);
}

.error-space {
    width: 90%;
    height: 30px;
    color: var(--color-primary-font);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mode-button {
    width: 90%;
    height: 30px;
    color: var(--color-primary-font);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
}

img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
}

.img-button {
    padding: 8px 16px;
    background-color: var(--color-button);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin: 10px;
    border-radius: 20px;
    user-select: none;
}