* {
    margin: 0; /* Reset margin to remove extra space */
  }

.post {
    width: 100%;
    height: fit-content;
    margin:5px;
    padding: 10px;
    /* border-bottom: 1px solid var(--color-border); */
}

.post-content-holder {
    margin: 10px 0px;
}

.post-content-holder a:hover {
    color: #09f;
}

.post-title {
    height: fit-content;
    width: 100%;
    text-align: left;
    font-size:20px;
    font-weight: bold;
    padding:5px 0px;
    line-height: 1.6;
}

.post-description {
    height: fit-content;
    width: 100%;
    text-align: left;
    font-size: 16px;
    padding:5px 0px;
    line-height: 1.6;
}

.is-last {
    border-bottom:none;
}

.post-bar {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-border);
}

.post-bar-sub {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
}

.post-bar-sub > * {
    margin: 0px 5px;
    cursor: pointer;
    color: var(--color-post-icon);
    padding: 5px 0px;
}


.post-header-container {
    width: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--color-border);
}

.post-header {
    width: 100%;
    display: flex;
}

.image-holder {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.image-holder img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
}

.text-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 12px;
}

.text-holder p{
    color: var(--color-faint);
    font-size: 12px;
}

.text-holder h2{
    font-size: 16px;
    color: var(--color-primary);
    font-weight: bold;
}

.comments-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 20px;
}

.comment-container {
    width: 100%;
    padding: 8px 0px;
    display: flex;
    justify-content: flex-start;
}

.comment-container img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.text-container *{
    text-align: left;
    line-height: 1.3;
    box-sizing: border-box;
    margin-left: 8px;
}

.text-container .username {
    font-size: 12px;
    font-weight: bold;
}

.text-container .username span{
    color: var(--color-faint);
}

.comment {
    font-size: 14px;
    padding:5px 0px;
    box-sizing: border-box;
}

.delete-button {
    color: var(--color-faint);
    cursor: pointer;
}

.comment-input-container {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 10px;
}

.comment-input-container img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.comment-input-form {
    width: 100%;
    box-sizing: border-box;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.comment-input {
    width: 100%;
    height: 70px;
    border: 1px solid var(--color-faint);
    font-size: 14px;
    border-radius: 5px;
    resize: none;
    background-color: transparent;
    box-sizing: border-box;
    color: var(--color-font-primary);
    padding: 8px 12px;
}

.comment-input:focus {
    border-color: #007bff;
    outline: none;
}

.comment-input-buttons-holder {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.comment-input-buttons-holder button {
    padding: 8px 16px;
    background-color: var(--color-button);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin: 10px 0px 10px 10px;
    border-radius: 20px;
    user-select: none;
    border: none;
    color: var(--color-font-primary);
}

.vote-button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vote-button.pressed {
    color: var(--color-button);
}

.vote-button * {
    margin: 0px 4px;
}

.bookmark {
    color: var(--color-bookmark);
}

@media screen and (max-width: 600px) {
    .post {
        width: 90%;
        padding: 5px;
    }

    .post-title {
        font-size:16px;
        line-height: 1.3;
        padding:3px 0px;
    }

    .post-description {
        font-size: 13px;
        line-height: 1.3;
        padding:3px 0px;
    }

    .post-content-holder {
        margin: 5px 0px;
    }

    .post-header-container {
        padding-top: 12px;
    }
    
    .image-holder {
        width: 32px;
        height: 32px;
    }
    
    .image-holder img {
        width: 32px;
        height: 32px;
    }
    
    .text-holder {
        padding-left: 8px;
    }
    
    .text-holder p{
        font-size: 12px;
    }
    
    .text-holder h2{
        font-size: 14px;
    }

    .comment-input-buttons-holder button {
        padding: 6px 12px;
        margin: 6px 0px 6px 6px;
    }

    .comment-input-container {
        padding-top: 10px;
    }
    
    .comment-input-container img {
        width: 40px;
        height: 40px;
    }

    .comment-container {
        padding: 6px 0px;
    }
    
    .comment-container img {
        width: 40px;
        height: 40px;
    }

    .comment {
        padding:3px 0px;
    }

    .post-bar-sub > * {
        margin: 0px 3px;
        padding: 3px 0px;
    }
}
