.navbar-buttons {
    width: fit-content;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:15px;
    color: var(--color-primary-font);
}

.create-button {
    height:100%;
    padding:0px 16px;
    border-radius: 50px;
    cursor: pointer;
    color: var(--color-primary-font);
    font-size: 0.875rem;
    border: none;
    font-weight: bold;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
    text-decoration: none;
}

.create-button-icon {
    width: 20px;
    height: 20px;
    padding-right: 8px;
}

.create-button:hover {
    background-color: var(--color-hover);
}

.notification-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.notification-icon {
    width: 30px;
    height: 30px;
}

.notification-count {
  top: 5px;
  right: -1px;
  width: 15px;
  height: 12px;
  display: block;
  position: absolute;
  background-color: #ff3b30;
  border-radius: 8px;
  line-height: 12px;
  font-size: 10px;
  font-weight: 400;
  color: var(--color-primary-font);
  text-align: center;
  cursor: pointer;
}


.notification-button:hover {
    background-color: var(--color-hover);
}

.profile-button {
    position: relative; /* Ensure the overlay is positioned relative to the button */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden; /* Ensure the overlay stays within the bounds of the button */
    cursor: pointer;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
}

.overlay:hover {
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black color on hover */
}

.profile-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 66px;
    height: 40px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: 4px;
    bottom: 4px;
    background-color: var(--color-primary-font);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .auth-button {
    height: 100%;
    min-width: 60px;
    font-size: 14px;
    font-weight: bold;
    color: var(--color-faint-light);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
  .navbar-buttons {
    height: 32px;
    gap:7px;
  }

  .create-button {
    display: none;
  }

  .notification-button {
    width: 32px;
    height: 32px;
  }

  .notification-icon {
      width: 24px;
      height: 24px;
  }
  
  .profile-button {
    width: 32px;
    height: 32px;
  }

  .switch {
    width: 44px;
    height: 30px;
  }
  
  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 5px;
    background-color: var(--color-primary-font);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

}