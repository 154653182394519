.settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 16px;
}

img {
    width: 120px;
    height: 120px;
    object-fit: cover;
}

button {
    padding: 8px 16px;
    background-color: var(--color-button);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin: 10px;
    border-radius: 20px;
    user-select: none;
    border:none;
    color: var(--color-primary-font);
}

input {
    width: 80%;
    height: 40px;
    background-color: transparent;
    padding: 8px 12px;
    border: 1px solid var(--color-faint);
}