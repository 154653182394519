.posts-list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

a {
    text-decoration: none;
    color: var(--color-primary-font);
}