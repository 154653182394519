:root {
  --color-background-primary: #0B1416;
  --color-background-secondary: #1A282D;
  --color-border: rgba(128, 128, 128, 0.5);
  --color-faint: #808080;
  --color-faint-light: #999999;
  --color-post-icon: rgba(160, 160, 160, 0.8);
  --color-primary-font: white;
  --color-hover: #223237;
  --color-icon: #F2F4F5;
  --color-button: #0077FF;
  --color-bookmark: #FF3B30;
}

[data-theme="light"] {
  --color-background-primary: #FFFFFF;
  --color-background-secondary: #F2F2F2;
  --color-border: rgba(0, 0, 0, 0.1);
  --color-faint-light: #CCCCCC;
  --color-faint: #999999;
  --color-post-icon: rgba(0, 0, 0, 0.5);
  --color-primary-font: #333333;
  --color-hover: #E5E5E5;
  --color-icon: #333333;
  --color-button: #0077FF;
  --color-bookmark: #FF3B30;
}

* {
  transition: background-color 0.4s ease;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

body {
  padding: 0px;
  margin: 0px;
  background-color: var(--color-background-primary);
}

.infinite-scroll-component__outerdiv {
  width:100%;
}

input, textarea {
  font-family: Arial, sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--color-background-secondary);
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--color-border);

  border-radius: 5px; 
  border: 2px solid transparent; 
}

/* Style the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change to your desired color */
}