.post-details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 16px;
    align-items: center;
    color: var(--color-primary-font);
}

.post {
    width: 100%;
    height: fit-content;
    padding: 10px 0px;
}

.post-title {
    height: fit-content;
    width: 100%;
    text-align: left;
    font-size:20px;
    font-weight: bold;
    padding:5px 0px;
    margin-bottom: 5px;
    line-height: 1.6;
}

.post-description {
    height: fit-content;
    width: 100%;
    text-align: left;
    font-size: 16px;
    padding:5px 0px;
    line-height: 1.6;
}

@media screen and (max-width: 600px) {
    .post-details {
        width: 90%;
        padding-top: 12px;
    }

    .post-title {
        font-size: 18px;
        line-height: 1.3;
        padding:3px 0px;
        margin-bottom: 0px;
    }

    .post-description {
        font-size: 14px;
        line-height: 1.3;
        padding:3px 0px;
    }
}