.profile {
    width: 240px;
    height: fit-content;
    border-radius: 5px;
    position: absolute;
    top: 60px;
    right: 30px;
    color: var(--color-primary-font);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding:5px 0px;
    background-color: var(--color-background-primary);
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.75);
}

.profile-list-item {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 16px;
    box-sizing: border-box;
}

.profile-list-item:hover {
    background-color: var(--color-hover);
}

@media screen and (max-width: 600px) {
    .profile {
        top: 45px;
        right: 10px;
    }
}